.open-orders-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  min-height: 18.75rem;
  padding-top: 1.25rem;
  max-height: 25rem;
  overflow-y: scroll;

  .open-order-table-header {
    height: 3rem;
    background-color: $charade;

    span {
      margin-left: 0.75rem;
      color: $bombay;
      font-size: 0.75rem;
      font-family: Poppins, sans-serif;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 12rem;

    span {
      color: $white;
      font-family: Poppins, sans-serif;
      font-size: 1.375rem;
    }
  }
}

.open-orders-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.open-orders-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
