.footer {
  width: 100%;
  background-color: #1a1c22;
  box-sizing: border-box;
  padding: 4% 10%;

  .firstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headerText {
      color: $white;
      font-family: Poppins;
      font-size: 2rem;
      font-weight: 600;
      line-height: 48px;
    }

    .btnContainer {
      margin-left: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      padding: 0 0.625rem;
      height: 46px;
      background-color: $cornflower-blue-ligth;
    }

    .getStartedContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .logoImage {
      width: 3rem;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    margin: 40px 0;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .secondRow {
    display: flex;
    flex-direction: row;

    .emailSubContainer {
      display: flex;
      flex-direction: row;
      margin-top: 28px;

      input {
        color: $white;
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        background-color: transparent;
        border: none;
      }
      input:focus {
        outline: none;
      }
    }
  }
}

.footer .secondRow .subBtn {
  display: flex;
  width: 53px;
  height: 53px;
  justify-content: center;
  align-items: center;
  background-color: $cornflower-blue-ligth;
  border-radius: 8px 8px 0 0px;
}

.footer .secondRow .subBtn:hover {
  cursor: pointer;
}

.footer .secondRow .internalLinksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 135px;
}

.footer .secondRow .internalLinksContainer .firstColumn {
  margin-right: 95px;
}

.footer .secondRow .internalLinksContainer .secondColumn {
  margin-right: 130px;
}

.footer .secondRow .internalLinksContainer span {
  color: #b4b6c1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.footer .secondRow .internalLinksContainer p {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 16px;
}

.footer .thirdRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 55px;
}

.footer .thirdRow .subHeaderText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.footer .thirdRow .socials .lnk {
  font-size: 25px;
  color: #ffffff;
  margin-left: 24px;
}

@media (max-width: 768px) {
  .footer {
    padding: 4%;
    width: 100%;
  }
  .footer .headerText {
    font-size: 16px;
  }
  .footer .rowWrapper {
    width: 100%;
    overflow: hidden;
  }
  .footer .secondRow {
    width: 100%;
  }
  .footer .secondRow .internalLinksContainer,
  .footer .firstRow {
    display: none;
  }
  .footer .secondRow .emailSubContainer {
    justify-content: space-between;
    margin-top: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .footer .secondRow .emailSubContainer input {
    font-size: 12px;
  }
  .footer .divider {
    margin: 5% 0;
  }
}

.marketplace-footer-container {
  width: 100%;
  margin: 0 auto;
  background: rgba(35, 38, 49, 0.8);
  box-shadow: 0 1em 1.5em rgb(0 0 0 / 15%);
  backdrop-filter: blur(100px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .footer-container {
    display: flex;
    flex-direction: row;
    padding: 0 36px;
    align-items: center;
    height: 100px;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    .socials-container {
      color: $white;
      text-decoration: none;
      font-size: 1.56rem;

      span {
        padding-left: 1.25rem;
      }
    }
  }

  span {
    color: $white;
  }
}
