.order-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  width: 8.75rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  &:hover {
    cursor: pointer;
  }

  p {
    color: $mercury;
    font-size: 1.25rem;
    font-family: Poppins;
  }
}

.order-button.disabled:hover {
  background: grey;
}

.order-button.disabled {
  background: grey !important;
  cursor: not-allowed;
  border: 1px solid gray !important;
}
