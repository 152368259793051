.order-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  padding: 0 1rem;
  margin: 0 1rem;
  border-bottom: 1px solid #333;
  max-width: 80%;
  &:hover {
    background-color: rgb(204 204 204 / 8%);
    cursor: pointer;
  }

  .order-quantity {
    display: flex;
    flex-direction: row;

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }

  span {
    color: $white;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
  }

  span.ask {
    color: $sunset-orange;
  }

  span.bid {
    color: $aqua-forest;
  }
}
