.marketplace-container {
  display: flex;
  flex: 1;
  padding: 0 10%;
  margin: 0 auto;
  flex-direction: column;
  max-width: 1200px;
  padding-bottom: 50px;

  h1 {
    color: $white;
    font-size: 2.5rem;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    margin-top: 6.25rem;
    margin-left: 1rem;
    margin-bottom: 0.75rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);

    @media (max-width: 768px) {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .items-container {
    display: flex;
    flex-direction: row;
    margin-top: 1.25rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .item-container {
      display: flex;
      margin-left: 1rem;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      background-color: $shark;
      border-radius: 1rem;
      padding: 1.5rem;
      filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);

      @media (max-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 1.25rem;
      }

      .resource-image-container {
        display: flex;
        height: 14.3rem;
        width: 100%;
        background-color: $charade;
        border-radius: 1rem;
        justify-content: center;
        align-items: center;

        @media (max-width: 768px) {
          height: 6.85rem;
        }

        img {
          width: 80%;

          @media (max-width: 768px) {
            width: 20%;
          }
        }
      }

      .item-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .item-text {
          color: $white;
          line-height: 1.875rem;
          font-size: 1.25rem;
          font-family: Poppins, sans-serif;
          margin-bottom: 0.75rem;
          margin-top: 0.625rem;
        }
        .item-desc {
          color: $bombay;
          font-family: Poppins, sans-serif;
          font-size: 0.75rem;
          margin-bottom: 1.25rem;
        }
      }
    }
  }
}
