body {
  padding: 0;
  margin: 0;
  background-color: $charade;
  background-size: cover;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
button.wallet-adapter-button-trigger {
  background: rgba(130, 93, 251, 0.8);
}
// this is needed for sticky footer
div#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

// remove arrows from inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
