.navigation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.5rem;
  width: 100%;
  background-color: $woodsmoke_2;
  border-radius: 1rem 1rem 0 0;

  .navigation-order-types {
    display: flex;
    margin-left: auto;
    justify-content: center;
    width: 45%;
    padding: 3px;
    margin-right: 1.5rem;
    align-items: center;
    border-radius: 0.5rem;
    background: $shark;
    font-size: 1.3rem;

    .navigation-order-type-btn {
      display: flex;
      width: 100%;
      height: 2.5rem;
      border-radius: 0.5rem;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
    }

    .navigation-order-type-btn.active {
      background-color: $charade;
    }
  }
}
