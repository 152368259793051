.open-order-row-container {
  height: 3.5rem !important;
  margin-bottom: 0.5rem;
  padding: 2px;

  td {
    color: $white;
    font-family: Poppins;
    font-size: 0.875rem;
    margin-left: 1rem;

    span {
      margin-left: 0.75rem;
    }
    span.sell {
      color: $carnation;
    }
    span.buy {
      color: $dark-green;
    }
  }

  .cancel-order-button {
    border: 1px solid $sunset-orange;
    width: 6.5rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    background-color: $woodsmoke-2;

    &:hover {
      cursor: pointer;
      background: $carnation;
    }

    p {
      color: $mercury;
      font-family: Poppins, sans-serif;
      font-size: 1rem;
    }
  }
}
