$white: white;
$shark: #1c1d23;
$charade: #232631;
$bombay: #b4b6c1;
$cornflower-blue: #825dfb;
$cornflower-blue-ligth: #8a61fa;
$gray: #ccc;
$woodsmoke-2: #141316;
$aqua-forest: #52ab73;
$mercury: #e3e3e3;
$ebony-clay: #2f3344;
$sunset-orange: #ff5252;
$carnation: #f95959;
$dark-green: #006400;
$baltik_sea: #222127;
