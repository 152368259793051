.market-page-container {
  display: flex;
  padding: 0 10%;
  margin: 0 auto;
  flex-direction: column;
  max-width: 1200px;
  padding-bottom: 3.125rem;

  .item-details-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 2rem;

    .header-text {
      color: $white;
      font-size: 2.5rem;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      line-height: 3.75rem;
      letter-spacing: 0em;

      @media (max-width: 768px) {
        display: none;
      }
    }
    .header-text-mobile {
      color: $white;
      font-size: 2.5rem;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      line-height: 3.75rem;
      letter-spacing: 0em;

      @media (min-width: 768px) {
        display: none;
      }
      @media (max-width: 768px) {
        display: block;
      }
    }

    .back-button {
      display: flex;
      flex: 1;
      max-width: 3.75rem;
      flex-direction: row;
      align-items: center;
      color: $white;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }
      span {
        margin-left: 4px;
        font-size: 1rem;
      }
    }
  }

  .item-container {
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      display: none;
    }

    .item-icon-container {
      display: flex;
      width: 15.6rem;
      height: 15.6rem;
      flex-direction: column;
      align-items: center;
      background-color: $shark;
      border-radius: 1rem;
      padding: 0 2rem;
      margin-right: 2rem;

      img {
        width: 100%;
        z-index: 2;
      }

      .balance-container {
        background: $shark;
        color: $white;
        width: 100%;
        padding: 1rem 2rem;
        margin-top: 1.25rem;
        border-radius: 0.75rem;
        border: 1px solid $cornflower-blue;
        text-align: center;

        span {
          color: $cornflower-blue;
        }
      }
    }

    .market-container {
      display: flex;
      flex-direction: column;
      height: 70%;
      width: 48.75rem;
      color: $white;
      font-family: Poppins;

      .market-info-container {
        display: flex;
        flex-direction: row;
        background-color: $shark;
        height: 100%;
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
        position: relative;

        .orders-container {
          display: flex;
          flex: 2;
          flex-direction: column;
          justify-content: space-between;

          .all-orders-container {
            display: flex;
            flex-direction: column;
            border-radius: 0.625rem;

            .orders-list-container {
              display: flex;
              flex-direction: row;
              min-height: 18.75rem;

              .orders-list {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 25rem;
                overflow-y: scroll;

                .loading-container {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 12.5rem;

                  span {
                    color: $white;
                    text-align: center;
                    font-family: Poppins, sans-serif;
                    font-size: 1.375rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
