.order-actions-container {
  display: flex;
  height: 5.5rem;
  align-items: center;
  box-shadow: inset 0px 2px 0px $charade;

  .form-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 2.5rem;

    .form-control {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        color: $gray;
        font-family: Poppins;
        font-size: 1rem;
        margin-right: 0.5rem;
      }

      input {
        height: 3rem;
        max-width: 5rem;
        padding: 0 0.75rem;
        color: $white;
        background-color: $woodsmoke-2;
        border: none;
        border-radius: 0.5rem;
      }
    }
    .form-control:last-child {
      margin-left: 1rem;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    .buy {
      background-color: $aqua-forest;
    }

    .sell {
      background-color: $cornflower-blue;
    }
  }
}
