.header {
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-top: 25px;
  background-color: transparent;

  .menuItems {
    display: flex;
    flex-direction: row;
    height: 46px;

    .btnContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      padding: 0 10px;
    }

    .menuItem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      .menuText {
        color: $white;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
      }

      &:hover {
        border-bottom: 3px solid #d43a9d;
      }
    }
  }
}

.header .hamburger,
.headerWrapper .mobMenuItems {
  display: none;
}

.header .menuItems .btnContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(to right, #743ad5, #d53a9d) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.header .menuItems .btnContainer:hover {
  cursor: pointer;
}

.header .menuItems .btnContainer .btnText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

@media (max-width: 768px) {
  .header {
    width: 100%;
    height: 70px;
    padding: 4%;
    background: rgba(35, 38, 49, 0.8);
    box-shadow: 0 1em 1.5em rgb(0 0 0 / 15%);
    backdrop-filter: blur(100px);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;

    .menuItems {
      display: none;
    }

    .hamburger {
      display: flex;
      width: 2.2rem;
      height: 2.2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .headerWrapper .mobMenuItems {
    display: flex;
    flex-direction: column;
    background: rgba(35, 38, 49, 0.8);
    width: 100%;

    .menuText {
      color: #ffffff;
      font-family: Poppins;
      font-size: 1.1rem;
      height: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1rem;
    }

    .divider {
      height: 1px;
      margin: 0 1rem;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.marketplace-header-container {
  width: 100%;
  margin: 0 auto;
  background: rgba(35, 38, 49, 0.8);
  box-shadow: 0 1em 1.5em rgb(0 0 0 / 15%);
  backdrop-filter: blur(100px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .header-container {
    display: flex;
    padding: 0 2.1rem;
    flex-direction: row;
    align-items: center;
    height: 6.25rem;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;

    @media (max-width: 768px) {
      height: 75px;
    }

    .menu-container {
      display: flex;
      flex-direction: row;
      @media (max-width: 768px) {
        display: none;
      }

      .header-button {
        display: flex;
        width: 7.5rem;
        justify-content: center;
        align-items: center;

        span {
          font-family: Poppins, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: $bombay;
        }

        span.active {
          color: $white;
        }
      }
    }

    .buttons-container {
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }

      .wallet-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          color: $gray;
          font-size: 1rem;
          margin-right: 1rem;
        }
      }

      .contact-us {
        display: flex;
        color: $white;
        padding: 0 24px;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(130, 93, 251, 0.8);
        border-radius: 0.5rem;
        margin-right: 1rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.contact-us-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 160px;
  border-radius: 1rem;
  box-shadow: 24;
  background: $baltik_sea;
  border: 2px solid rgba(130, 93, 251, 0.8);
  padding: 1rem;

  .close-modal {
    display: flex;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }
  }

  span {
    font-family: Poppins;
    font-size: 1rem;
    margin: 5px 0;
    color: $white;
  }

  a {
    text-decoration: underline;
    color: $white;
  }
}
