img {
  max-width: 100%;
}

.background1Wrapper {
  box-sizing: border-box;
  background: #232631;
  min-height: 100vh;
}

.background2Wrapper,
.background3Wrapper,
.background1Wrapper {
  background-repeat: no-repeat;
  background-size: cover;
}

.block1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 9rem;
  padding-top: 4.2rem;
}

.block1 .rightContainer {
  margin-left: 7.8rem;
}

.block1 .animatedPreview {
  opacity: 0;
  animation: appear 1s ease forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.block1 .leftTextContainer .headerText {
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  background: linear-gradient(124.61deg, #825dfb 14.75%, #e08def 95.23%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.block1 .leftTextContainer .subHeaderText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 24px;
  max-width: 460px;
}

.block1 .leftTextContainer .outlinedText {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  -webkit-text-stroke: 1px #ffffff;
  color: transparent;
}

.block1 .leftTextContainer .boldText {
  color: #ffffff;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
}

.block1 .leftTextContainer .pinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 46px;
  background: $cornflower-blue-ligth;
  border-radius: 8px;
  margin-top: 24px;
}

.block1 .leftTextContainer .pinkButton:hover,
.footer .btnContainer {
  cursor: pointer;
}

.block1 .leftTextContainer .pinkButtonText {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .block1 {
    flex-direction: column;
    padding: 0 4%;
    margin-top: 0;
  }
  .block1 .pinkButton {
    display: none;
  }
  .block1 .leftTextContainer {
    padding-top: 32px;
    margin-bottom: 16px;
  }
  .block1 .rightContainer {
    margin: 0;
  }
  .block1 .leftTextContainer .headerText {
    font-size: 48px;
  }
  .block1 .leftTextContainer .outlinedText,
  .block1 .leftTextContainer .boldText {
    font-size: 36px;
    line-height: 42px;
  }
  .block1 .leftTextContainer .pinkButton {
    margin: 16px auto;
  }
}

.block2Wrapper {
  // background: #1a1c22;
}

.block2 {
  // height: 1200px;
  display: flex;
  flex-direction: row;
  background: #1a1c22;
  padding-top: 50px;
}

.blockVideo {
  text-align: center;
}

.block3 {
  padding-top: 4rem;
  box-sizing: border-box;
}

.blockVideo .videoContainer {
  max-height: 540px;
  padding: 20px 0;
  background: #232631;
}

.blockVideo .youtubeContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  max-width: 960px;
  margin: 0 auto;
}

.blockVideo .youtubeContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  // width: 100%;
  height: 100%;
  max-height: 540px;
  padding: 20px;
  background: #1a1c21;
  border-radius: 15px;
  width: calc(100% - 40px);
}

.block2 .leftImages,
.block2 .rightImages {
  width: 33%;
}

.imagePreviews {
  display: flex; 
  flex-direction: row;
  margin: 5px 0;
}

.imagePreviews .imageItem {
  flex: 1;
  margin: 0 5px;
  border: 3px solid #4d4266;
  border-radius: 7px;
  overflow: hidden;
}

.imageItem img {
  height: 100%;
  transition: transform 0.5s;
}

.imageItem img:hover {
  transform: scale(1.5);
}

.block2 .leftImages .blueCircle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(180deg, #52ab73 0%, #58c8ff 100%);
  margin-left: 171px;
  margin-top: 205px;
}

.block2 .rightImages .pinkCircle {
  margin-top: 50px;
  margin-left: 80%;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: linear-gradient(180deg, #e5b3e7 0%, #a288eb 100%);
}

.block2 .leftImages .mainInHatImage {
  width: 315px;
  margin-left: 116px;
  margin-top: 76px;
}

.block2 .leftImages .vikingWithVrImage {
  width: 112px;
  margin-top: 63px;
  margin-left: 65%;
}

.block2 .rightImages .manInPyro {
  margin-top: 120px;
  margin-left: 126px;
  width: 171px;
  height: 201px;
}

.block2 .rightImages .womenWithBottles {
  margin-top: 187px;
}

.block2 .central {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  text-align: center;
}

.block2 .centralImage {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .headerText {
    font-size: 16px;
  }
}

.roadmapWrapper .mainHeaderText {
  text-align: center;
  padding-top: 7rem;
}

.block2 .central .headerText,
.blockVideo .headerText,
.block3 .headerText,
.block4 .headerText,
.block5 .headerText,
.marketPlaceScreen .headerText,
.roadmapWrapper .mainHeaderText,
.block7 .headerText {
  color: #ffffff;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.block2 .central .headerNumber {
  margin-top: 4px;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 144px;
  letter-spacing: 0em;
  background: linear-gradient(124.61deg, #825dfb 14.75%, #e08def 95.23%),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.block2 .central .subHeaderText {
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0em;
  max-width: 480px;
  padding-bottom: 50px;
  margin: 0 auto;
}

.block2 .central .graySubHeader {
  margin-top: 12px;
  color: #b4b6c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

@media (max-width: 768px) {
  .block2 {
    width: 100%;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    background-image: none !important;
  }
  .block2 .central {
    width: 100%;
    padding-top: 36px;
  }
  .block2 .central .headerText {
    font-size: 24px;
  }
  .block2 .central .subHeaderText {
    font-size: 16px;
  }
  .block2 .central .graySubHeader {
    display: none;
  }
  .block2 .rightImages,
  .block2 .leftImages {
    display: none;
  }
}

.block3 .subHeaderText {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
}

.block3 .headerContainer {
  text-align: center;
  margin-bottom: 39px;
}

.block3 .buildingBlocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 41px;
}

.block3 .buildingBlocks .buildingBlock {
  box-sizing: border-box;
  width: 379px;
  padding: 40px;
  border-radius: 16px;
  background-color: #1a1c22;
}

.block3 .buildingBlocks .buildingBlock:hover {
  opacity: 0.8;
}

.block3 .buildingBlocks .buildingBlock .imageContainer {
  height: 200px;
  width: 299px;
  border-radius: 16px;
  background-color: #232531;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block3 .buildingBlocks .buildingBlock .headerText {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  margin-bottom: 12px;
}

.block3 .buildingBlocks .buildingBlock .subHeaderText {
  color: #b4b6c1;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.block3 .landBuildingBlock {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  overflow: visible;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  background-color: #1a1c22;
  border-radius: 16px;
  width: 1200px;
  height: 223px;
}

.block3 .landBuildingBlock .headerText {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.block3 .landBuildingBlock .subHeaderText {
  color: #b4b6c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.block3 .landBuildingBlock .landChart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 180px;
  background: linear-gradient(0deg, #232631, #232631);
  box-shadow: 0px 35px 45px rgba(7, 14, 39, 0.05);
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
}

.block3 .landBuildingBlock .landChart img {
  position: absolute;
}

.block3 .landBuildingBlock .landChart .chartPercentText {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
}

@media (max-width: 480px) {
  .block3 .buildingBlocks .buildingBlock {
    width: 100%;
  }
  .block3 .buildingBlocks .buildingBlock .imageContainer {
    width: 100%;
  }
  .block3 .headerContainer {
    padding-top: 10rem;
  }
}

@media (max-width: 768px) {
  .block3 {
    padding: 0 4%;
  }
  .block3 .headerContainer {
    margin-top: 16px;
    margin-bottom: 0;
  }
  .block3 .headerContainer .headerText {
    font-size: 36px;
  }
  .block3 .headerContainer .subHeaderText {
    font-size: 24px;
  }
  .block3 .buildingBlocks {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .block3 .buildingBlocks .buildingBlock {
    margin-top: 16px;
    height: 100%;
    max-width: 380px;
  }
  .block3 .landBuildingBlock {
    /* temp "fix" as we have too much content on home page*/
    display: none;
  }
  .block3 .headerContainer {
    padding-top: 5rem;
  }
}

.block4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 9rem;
}

.block4 .landOwnerShip {
  margin-left: 32px;
}

.block4 .landOwnerShip .subHeaderText,
.marketPlaceScreen .subHeaderText {
  color: #ffffff;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 600px;
}

.block4 .landOwnerShip .landOwnerShipText {
  margin-top: 24px;
  color: #b4b6c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 600px;
}

@media (max-width: 768px) {
  .block4 {
    display: none;
  }
}

.block5 .headerTextContainer {
  width: 1200px;
  margin: auto;
  margin-bottom: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 120px;
}

.block5 .headerTextContainer .subHeaderText {
  // margin-top: 32px;
  color: #b4b6c1;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 410px;
}

.block5 .headerTextContainer .subHeaderPink {
  background: linear-gradient(124.61deg, #825dfb 14.75%, #e08def 95.23%),
    linear-gradient(0deg, #b4b6c1, #b4b6c1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  max-width: 480px;
}

.block5 .buildingLevels {
  margin: 0 auto;
  width: 1200px;
  height: 560px;
  background-color: #1a1c22;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
}

.block5 .buildingLevels .levelsImages {
  width: 50%;
  text-align: center;
}

.block5 .buildingLevels .levelsImages .sawmillImage {
  width: 80%;
}

.block5 .buildingLevels .levelsImages .rowButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 78px;
}

.block5 .buildingLevels .levelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 40px;
  border-bottom: 3px solid #434652;
  margin-bottom: 58px;
}

.block5 .buildingLevels .levelButton:hover {
  cursor: pointer;
  border-bottom: 3px solid $cornflower-blue-ligth;
}

.block5 .buildingLevels .levelButton.activeLevel {
  border-bottom: 3px solid $cornflower-blue-ligth;
}

.block5 .buildingLevels .levelText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.block5 .buildingLevels .buildingRequirements {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 40px 32px;
}

.block5 .buildingLevels .buildingRequirements .subHeaderText {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.block5 .buildingLevels .buildingRequirements .constCostText {
  color: #b4b6c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 18px;
}

.block5 .buildingLevels .buildingRequirements .divider {
  border: 1px solid #434652;
  margin: 24px 0;
}

.block5 .constResources {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  min-height: 200px;
}

.block5 .constResources .constResource:hover {
  padding: 10px;
  opacity: 0.8;
}

.block5 .constResources .constResource {
  width: 156px;
  height: 172px;
  background-color: #2d303b;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block5 .constResources .constResource .grayText {
  color: #b4b6c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.block5 .constResources .constResource .whiteText {
  color: $white;
  font-size: 1rem;
  font-weight: 700;
  line-height: 24px;
}

.block5 .buildingLevels .buildingRequirements .pinkButton {
  background-color: $cornflower-blue-ligth;
  border-radius: 0.5rem;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block5 .buildingLevels .buildingRequirements .btnText,
.footer .btnText,
.footer .secondRow .subHeaderText {
  color: $white;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.block5 .buildingLevels .buildingRequirements .orangeText {
  color: #ffd041;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.block5 .buildingLevels .buildingRequirements .buildLevelText {
  display: flex;
  flex-direction: row;
}

.block5 .buildingLevels .buildingRequirements .trophyIcon {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background: rgba(255, 209, 57, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.centralBuildingBlock {
  margin: 0 32px;
}

@media (max-width: 768px) {
  .block5 {
    padding: 0 4%;
  }
  .block5 .headerTextContainer {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .block5 .headerTextContainer .internalTextContainer {
    margin: 0 auto;
  }
  .block5 .headerTextContainer .subHeaderText {
    text-align: center;
    font-size: 16px;
  }
  .block5 .headerTextContainer .subHeaderPink {
    display: none;
  }
  .block5 .headerText {
    font-size: 36px;
  }
  .block5 .buildingLevels {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .block5 .buildingLevels .levelsImages,
  .block5 .buildingLevels .buildingRequirements {
    width: 100%;
  }
  .block5 .buildingLevels .levelsImages .rowButtons {
    margin-top: 16px;
  }
  .block5 .constResource.stoneResource {
    margin: 0 16px;
  }
  .block5 .constResources {
    justify-content: space-between;
  }
  .block5 .constResources .constResource {
    width: 90%;
    height: 90%;
  }
  .block5 .constResources .constResource img {
    width: 60%;
  }
  .block5 .constResources .constResource .grayText {
    font-size: 12px;
  }
  .block5 .constResources .constResource .whiteText {
    font-size: 12px;
  }
}

.marketPlaceScreen {
  padding-top: 7.5rem;
  display: flex;
  text-align: center;
  justify-content: center;
}

.marketPlaceImage {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}

@media (max-width: 768px) {
  .marketPlaceScreen,
  .marketPlaceImage {
    display: none;
  }
}

.roadmap {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 1200px) {
  .roadmap .centralRoadmapRow {
    margin: 0 32px;
  }
}

.roadmap .centralRoadmapRow .headerText {
  margin-bottom: 32px;
}

.roadmap .roadmapItem {
  position: relative;
  box-sizing: border-box;
  background-color: #1a1c22;
  width: 380px;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  margin: 0 1.25rem;
}

.roadmap .roadmapItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 16px;
  border: 1px solid transparent;
  background: linear-gradient(to right, #825dfb, #e08def) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.roadmap .roadmapItem.roadmapBottomItem {
  margin-top: 2rem;
}

.roadmap .roadmapItem.roadmapRightItem {
  margin-top: 110%;
}

.roadmap .roadmapItem .roadmapImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35%;
  background-color: #232531;
  border-radius: 1rem;
  box-sizing: border-box;
}

.roadmap .roadmapItem .roadmapDate {
  margin-top: 24px;
  background: linear-gradient(124.61deg, #825dfb 14.75%, #e08def 95.23%),
    linear-gradient(0deg, #ffffff, #ffffff);
  font-size: 56px;
  font-weight: 700;
  line-height: 84px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap .roadmapItem .roadmapTextItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}

.roadmap .roadmapItem .roadmapTextItem .checkContainer {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.roadmap .roadmapItem .roadmapTextItem .checkContainer.done {
  background-color: #52ab73;
}

.roadmap .roadmapItem .roadmapTextItem .checkContainer.notDone {
  border: 1px solid #0f881f;
  background-color: #ffffff;
}

.roadmap .roadmapItem .roadmapText {
  margin-left: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.roadmap .mobHeaderText,
.roadmap .mobQ2Item,
.roadmap .mobQ3Item {
  display: none;
}

@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
  .roadmap {
    flex-direction: column;
    margin-top: 32px;
    padding: 0 4%;
  }
  .roadmap .roadmapItem {
    margin: 0 auto;
    margin-top: 0.5rem;
    width: 100%;
    max-width: 528px;
  }
  .roadmap .mobHeaderText {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .roadmap .mobQ2Item,
  .roadmap .mobQ3Item {
    display: block;
    margin-top: 32px;
  }
  .roadmap .centralRoadmapRow .headerText,
  .roadmap .mobQ2Duplicate,
  .roadmap .mobQ3Duplicate {
    display: none;
  }
  .roadmap .centralRoadmapRow {
    margin-top: 0;
  }
}

.block7 {
  display: flex;
  justify-content: center;
  padding: 0 7.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.block7 .linksContainer {
  margin-top: 120px;
  margin-bottom: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  width: 100%;
  border-radius: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 36px 48px;
}

.block7 .linksContainer .subHeaderText {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  max-width: 460px;
}

.block7 .btnContainer {
  display: flex;
  flex-direction: column;
}

.block7 .btnContainer:hover {
  cursor: pointer;
}

.block7 .btnText {
  color: #37426c;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 259px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  margin: 8px 0;
}

@media (max-width: 768px) {
  .block7 {
    margin: 0 4%;
    padding: 0 !important;
  }
  .block7 .linksContainer {
    height: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    background-size: auto;
  }
  .block7 .linksContainer .textContainer {
    display: none;
  }
}
