.outline-button {
  display: flex;
  width: 100%;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(130, 93, 251, 0.8);
  border-radius: 0.5rem;
  background-color: $shark;

  &:hover {
    cursor: pointer;
  }

  p {
    color: $white;
    font-family: Poppins, sans-serif;
  }
}
